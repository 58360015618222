<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="申请人">
          <el-input v-model="dataForm.fillName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button @click="getDataList()" v-if="newAuth('cost:travel:find')" size="small" type="primary">查询</el-button>
        <el-button @click="downLoad()" size="small" type="success">导出</el-button>
        <el-form-item style="text-align: right;float: right;">
          <el-button size="small" v-if="newAuth('cost:travel:updateStatus')" @click="operatopnList('pass','','',2)"
                     type="primary"
                     :disabled="selectFeeData.length ? false : true">费用审核通过</el-button>
          <el-button size="small" v-if="newAuth('cost:travel:updateStatus')" @click="operatopnList('pass','','',1)"
                     type="primary"
                     :disabled="selectFeeData.length ? false : true">反审核</el-button>
          <el-button size="small" v-if="newAuth('cost:travel:updateWriteStatus')" type="success" @click="batchWrite(1)" :disabled="!selectFeeData.length">已付款核销</el-button>
          <el-button size="small" v-if="newAuth('cost:travel:updateWriteStatus')" type="success" @click="batchWrite(2)" :disabled="!selectFeeData.length">反核销</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox customer-service-department-table">
      <el-table :data="dataList" border size="medium" :height="tableHeight"  ref="table"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="40" align="center" fixed="left"></el-table-column>
        <el-table-column prop="customerName" label="客户名称" align="center" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="customerContactName" label="客户联系人" align="center" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="remark" label="费用备注">
        </el-table-column>
        <el-table-column :formatter="dictConvert" prop="payType" label="费用类型" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="money" label="金额" width="70" align="center">
        </el-table-column>
        <el-table-column label="费用凭证" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, [7011])" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column :formatter="dictConvert" prop="paymentType" label="结算方式" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="fillName" label="申请人" width="80" align="center">
        </el-table-column>
        <el-table-column prop="fillTime" label="申请日期" width="130" align="center">
        </el-table-column>
        <el-table-column width="90" label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="addOrUpdateHandle('view',scope.row.id)" size="small">详情</el-button>
            <el-button type="text" v-if="newAuth('cost:travel:update')" @click="addOrUpdateHandle('edit',scope.row.id)" size="small">修改</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status" fixed="right" width="60" label="审核状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" size="small" type="danger">待审核</el-tag>
            <el-tag v-else size="small" type="success">已审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="writeStatus" label="付款状态" fixed="right" width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.writeStatus === 2" size="small" type="danger">未付款</el-tag>
            <el-tag v-else size="small" type="success">已付款</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
    <!-- 弹窗, 新增营销跟踪 -->
    <market-track v-if="addMarketTrackVisible" ref="marketTrack" @refreshDataList="getDataList"></market-track>
  </div>
</template>

<script>
import writeOffPopup from './detail/write-off-popup'
import viewImage from '@/views/commonPop/viewImage'
import marketTrack from '@/views/modules/customer/details/market-track'

export default {
  data () {
    return {
      dataForm: {
        takeGoodsTime: null,
        customerName: null,
        fillName: null,
        writeStatus: null
      },
      tableHeight: '71vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      pageSizes: [20, 50, 100],
      dataListLoading: false,
      dataListSelections: [],
      addMarketTrackVisible: false,
      selectFeeData: [],
      userJobMap: new Map()
    }
  },
  components: {
    viewImage,
    writeOffPopup,
    marketTrack
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '72vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['table'].doLayout()
    })
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    examine () {

    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          if (column.property && (column.property.indexOf('money') > -1)) {
            return this.getValue(item.money === undefined ? 0 : item.money)
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (summaryTotal) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', summaryTotal, '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    operatopnList (type, todo, row, status) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.id)
          })
        }
        this.$confirm(status === 2 ? '正在执行【费用审核通过】操作，是否继续?' : '正在执行【反审核】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/marketTrack/audit/status'),
            method: 'post',
            data: {
              ids: ids,
              status: status
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => {})
      }
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/export/travelCostExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName,
          'fillName': this.dataForm.fillName
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '出差费用表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/marketTrack/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName,
          'fillName': this.dataForm.fillName
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          if (this.totalPage > 100) {
            this.pageSizes = [20, 50, 100, this.totalPage]
          } else {
            this.pageSizes = [20, 50, 100]
          }
          if (data.summaryTotal != null || data.summaryTotal !== undefined) {
            this.getAllSummaries(data.summaryTotal || {})
          }
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    batchWrite (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/marketTrack/audit/writeStatus`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addMarketTrackVisible = true
      this.$nextTick(() => {
        this.$refs.marketTrack.init(todo, id)
      })
    }
  }
}
</script>
<style lang="less">
.customer-service-department-table {
  .el-table{
    overflow:visible !important;
    .el-table__fixed-right {
      background: #f5f7fa;
    }
  }
  .el-table__body-wrapper{
    z-index: 2;
  }
  .cell.el-tooltip {
    min-width: 0;
    padding: 0 !important;
  }
  .hover-row,.selected-row {
    td {
      background: rgb(250, 195, 100) !important;
    }
    .el-input__inner {
      background: rgb(250, 195, 100);
    }
  }
  .el-input__inner {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .el-input__inner:hover {
    background: #fff
  }
}
</style>
